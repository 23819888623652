<template>
  <div class="segments">
    <div class="type-segment-card-components">
      <type-segment-card />
    </div>

    <div class="segments-head-table-components">
      <segments-head-filter />
    </div>
    <div class="segments-table-components">
      <segments-table
        :items="items"
        :total="total"
        :is_loading="isLoading"
        @open_group-table="openGroupTable"
        @refetch="refetch"
      />
    </div>
    <new-segment-sidebar @refetch="refetch" />
    <pagination
      :total="total"
      table_name="segments"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import Pagination from '@/views/shared/components/table/Pagination'
  import NewSegmentSidebar from '../components/sidebars/NewSegmentSidebar.vue'
  import SegmentsTable from '../components/SegmentsTable.vue'
  import TypeSegmentCard from '../components/TypeSegmentCard.vue'
  import SegmentsHeadFilter from '../components/SegmentsHeadFilter.vue'
  export default {
    name: 'Segments',

    components: {
      Pagination,
      NewSegmentSidebar,
      SegmentsTable,
      TypeSegmentCard,
      SegmentsHeadFilter
    },

    apollo: {
      ReadSegments: {
        query: require('../gql/ReadSegments.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            pagination: {
              skip: this.pagination.skip,
              take: this.pagination.take
            }
          }
        },
        result({ data }) {
          const response = data?.ReadSegments?.list ?? []
          response.forEach((el) => (el.isOpenGroup = false))
          this.items = response
          this.isLoading = false
          this.total = data?.ReadSegments?.total
        },
        error(error) {
          this.isLoading = false
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        items: [],
        isLoading: true,
        total: null,
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },

    methods: {
      refetch() {
        this.$apollo.queries.ReadSegments.refetch()
      },

      openGroupTable(item, index) {
        item.isOpenGroup = !item.isOpenGroup
        this.$set(this.items, index, item)

        const rules = item.rules ?? []
        if (item.isOpenGroup) {
          rules.forEach((el) => (el.type = item.type))

          this.items.splice(index + 1, 0, ...rules)
        } else {
          this.items.splice(index + 1, rules.length)
        }
      },

      handlerPagination(option) {
        this.pagination.skip = (option.current_page - 1) * option.take
        this.pagination.take = option.take
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .e-select-el__option-name {
    margin-bottom: 0px !important;
  }
  :deep .search {
    width: 1px;
  }
  .segments {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .type-segment-card-components {
    margin-bottom: 22px;
  }

  .segments-head-table-components {
    margin-bottom: 16px;
  }

  .segments-table-components {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
</style>
