var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segments"
  }, [_c('div', {
    staticClass: "type-segment-card-components"
  }, [_c('type-segment-card')], 1), _c('div', {
    staticClass: "segments-head-table-components"
  }, [_c('segments-head-filter')], 1), _c('div', {
    staticClass: "segments-table-components"
  }, [_c('segments-table', {
    attrs: {
      "items": _vm.items,
      "total": _vm.total,
      "is_loading": _vm.isLoading
    },
    on: {
      "open_group-table": _vm.openGroupTable,
      "refetch": _vm.refetch
    }
  })], 1), _c('new-segment-sidebar', {
    on: {
      "refetch": _vm.refetch
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "segments"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }