<template>
  <div class="segment-card">
    <div class="segment-card__content">
      <div class="segment-card__title">Тип карт сегментов</div>
      <div class="segment-card__card-block">
        <type-item-card
          :type_card="getCardTypeList[0].name"
          :card_list="getCardTypeList"
          :description="getCardTypeList[0].description"
          @set_type_card="setTypeCard"
        />

        <type-item-card
          :type_card="getCardTypeList[1].name"
          :card_list="getCardTypeList"
          :description="getCardTypeList[1].description"
          @set_type_card="setTypeCard"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import TypeItemCard from './TypeItemCard.vue'
  export default {
    name: 'TypeSegmentCard',

    components: {
      TypeItemCard
    },

    computed: {
      ...mapGetters({
        getCardTypeList: 'marketing/getCardTypeList'
      })
    },

    methods: {
      ...mapMutations({
        SET_CARD_TYPE_LIST: 'marketing/SET_CARD_TYPE_LIST'
      }),

      setTypeCard(data) {
        this.SET_CARD_TYPE_LIST(data)
      }
    }
  }
</script>

<style scoped lang="scss">
  .segment-card {
    &__title {
      color: var(--text-primary-gray-700, #313131);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 12px;
    }

    &__card-block {
      display: flex;
      gap: 16px;
    }
  }
</style>
