var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segment-card"
  }, [_c('div', {
    staticClass: "segment-card__content"
  }, [_c('div', {
    staticClass: "segment-card__title"
  }, [_vm._v("Тип карт сегментов")]), _c('div', {
    staticClass: "segment-card__card-block"
  }, [_c('type-item-card', {
    attrs: {
      "type_card": _vm.getCardTypeList[0].name,
      "card_list": _vm.getCardTypeList,
      "description": _vm.getCardTypeList[0].description
    },
    on: {
      "set_type_card": _vm.setTypeCard
    }
  }), _c('type-item-card', {
    attrs: {
      "type_card": _vm.getCardTypeList[1].name,
      "card_list": _vm.getCardTypeList,
      "description": _vm.getCardTypeList[1].description
    },
    on: {
      "set_type_card": _vm.setTypeCard
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }