var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segments-head"
  }, [_c('div', {
    staticClass: "segments-head__content"
  }, [_c('div', {
    staticClass: "segments-head__select-block"
  }, [_c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.currentyTpes,
      "options": _vm.allTypes,
      "multiple": "",
      "hide_bin": true,
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Все типы",
      "select_name": "roles_select"
    },
    on: {
      "input": function ($event) {
        {}
      }
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.currentyRules,
      "options": _vm.allRules,
      "multiple": "",
      "hide_bin": true,
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Все правила",
      "select_name": "roles_select"
    },
    on: {
      "input": function ($event) {
        {}
      }
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "segments-head__button-block"
  }, [_c('div', [_c('div', {
    staticClass: "b-table__actions ml-auto"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.new-segment-sidebar",
      modifiers: {
        "new-segment-sidebar": true
      }
    }],
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        {}
      }
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить сегмент ")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }