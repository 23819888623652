<template>
  <div
    class="card-item"
    :class="{
      'card-item__background-active': isActive,
      'card-item__background-no-active': !isActive
    }"
    @click="setTypeCard"
  >
    <div class="card-item__content">
      <div class="card-item__title-block">
        <div
          :class="{
            'card-item__flag-true': isActive,
            'card-item__flag-false': !isActive
          }"
        ></div>
        <div class="card-item__title">{{ type_card }}</div>
      </div>

      <div class="card-item__description-block">
        <div class="card-item__description-text">{{ description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TypeItemCard',

    props: {
      description: {
        type: String,
        default: ''
      },
      card_list: {
        type: Array,
        default: () => []
      },
      type_card: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        isActive: false
      }
    },

    watch: {
      card_list: {
        handler(newVal) {
          this.initComponent(newVal)
        },
        deep: true
      }
    },

    mounted() {
      this.initComponent(this.card_list)
    },

    methods: {
      initComponent(card_list) {
        const activeTypeCard = card_list.find((el) => el.value)
        if (activeTypeCard.name === this.type_card) this.isActive = true
        else this.isActive = false
      },

      setTypeCard() {
        const newCardList = []
        this.card_list.forEach((el) => {
          if (el.name === this.type_card) {
            el.value = true
            newCardList.push(el)
          } else {
            el.value = false
            newCardList.push(el)
          }
        })
        this.$emit('set_type_card', newCardList)
      }
    }
  }
</script>

<style scoped lang="scss">
  .card-item {
    border-radius: 6px;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    background: var(--gray-white, #fff);
    padding: 16px;
    width: 380px;
    cursor: pointer;

    &__title-block {
      display: flex;
      align-items: center;
    }

    &__title {
      color: var(--text-primary-gray-700, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 8px;
    }

    &__description-block {
      margin-top: 8px;
    }

    &__description-text {
      color: var(--text-secondary-gray-400, #888);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__flag-true {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 5px solid var(--text-system-blue-400, #00a3ff);
    }

    &__flag-false {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid var(--gray-gray-150, #e1e1e1);
    }

    &__background-active {
      background-color: var(--gray-white, #fff);
    }

    &__background-no-active {
      background-color: var(--secondary-b-50, #f2f3f6);
    }
  }
</style>
