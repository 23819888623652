<template>
  <div class="table-block">
    <div class="table-block__content">
      <div class="search-block">
        <b-form
          class="pos-r"
          @submit.prevent="handlerSearch"
        >
          <img
            class="search-block__img"
            src="/img/icons/marketing/search.svg"
            alt=""
          />

          <b-form-input
            v-model="search"
            type="text"
            class="search-block__input"
            placeholder="Поиск"
          />
          <b-button
            v-if="search?.length > 0"
            class="search-block__btn-clear-search-str"
            @click.prevent="clear"
          />
        </b-form>
      </div>

      <div class="table">
        <resizable-table
          ref="table_segments-type"
          class="table-segments-type"
          table_name="table-segments-type"
          :items="items"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="is_loading"
          @row-click="rowClicked"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="changeSelectAll"
            />
          </template>

          <template #body_id="{ item }">
            <div class="body-id-block">
              <div
                v-if="item?.__typename === 'RuleHead'"
                class="b-status"
                :class="{ success: !item?.blocked }"
              ></div>
              <div @click.stop>
                <b-form-checkbox
                  v-if="item?.__typename === 'RuleHead'"
                  :checked="selected?.some((el) => el === item?.id)"
                  position="center"
                  @change="(val) => addSelected(val, item.id)"
                />
              </div>
            </div>
          </template>

          <template #body_name="data">
            <div
              class="table-name-block"
              :class="{
                'child-block': data?.item?.__typename === 'AttachedSegment'
              }"
            >
              <img
                v-if="data?.item?.type === 'SAVING' && data?.item?.rules?.length > 1"
                src="/img/icons//settings/triangle.svg"
                class="mr-2"
                :class="{
                  'icon-triangle-no-active': !data?.item?.isOpenGroup,
                  'icon-triangle-active': data?.item?.isOpenGroup
                }"
                alt=""
                @click.stop="opemGroup(data?.item, data?.index)"
              />

              <div class="table-left-text">{{ data?.item?.name }}</div>

              <div
                v-if="data?.item?.__typename === 'AttachedSegment'"
                class="table-left-text"
              >
                Скидка {{ data?.item?.discount?.[0] }} %
              </div>
            </div>
          </template>

          <template #body_type="{ item }">
            <div
              v-if="item?.__typename === 'RuleHead'"
              class="table-left-text"
            >
              {{ item.type ? getTypeItem(item.type) : '--' }}
            </div>
            <div v-else></div>
          </template>

          <template #body_empty> </template>

          <template #body_blocked="{ item }">
            <div
              v-if="!item?.blocked && item?.__typename === 'RuleHead'"
              class="table-status-true"
            >
              Действует
            </div>
            <div
              v-if="item?.blocked && item?.__typename === 'RuleHead'"
              class="table-status-false"
            >
              Остановлена
            </div>
          </template>

          <template #infoTable>
            <div>Всего: {{ total }}</div>
          </template>
        </resizable-table>

        <custom-navbar
          class="table-navbar"
          :items="selected"
          item_name="Позиция"
          @clear="clearSelected"
        >
          <template #buttons>
            <b-button
              v-if="selected.length === 1"
              variant="dark"
              @click="changeIsBlockedTheSegment"
              >{{ getNameButton }}
            </b-button>
          </template>
        </custom-navbar>
      </div>
    </div>
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  export default {
    name: 'SegmentsTable',

    components: {
      ResizableTable,
      CustomNavbar
    },

    props: {
      items: {
        type: Array,
        default: () => []
      },
      is_loading: {
        type: Boolean
      },

      total: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        search: '',
        select_all: false,
        selected: [],
        fields: [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            width: 50
          },
          {
            key: 'name',
            checked: true,
            label: 'Наименование',
            width: 220
          },
          {
            key: 'type',
            checked: true,
            label: 'Правило',
            width: 220
          },
          {
            key: 'blocked',
            checked: true,
            label: 'Статус',
            width: 180
          },
          {
            key: 'empty',
            checked: true,
            label: '',
            width: 500
          }
        ]
      }
    },

    computed: {
      getNameButton() {
        let result = ''
        const currenrItem = this.items.find((el) => el.id === this.selected[0])
        if (currenrItem.blocked === true) result = 'Включить'
        if (currenrItem.blocked === false) result = 'Остановить'
        return result
      }
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.items.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.items.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    methods: {
      handlerSearch() {},

      async changeIsBlockedTheSegment() {
        const currenrItem = this.items.find((el) => el.id === this.selected[0])
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/UpdateSegment.gql'),
            variables: {
              input: {
                id: currenrItem.id,
                blocked: !currenrItem.blocked
              }
            }
          })
          this.$noty.show(`Успешно`)
          this.$emit('refetch')
          this.clearSelected()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      rowClicked(item) {
        if (item.__typename === 'RuleHead' && item.rules.length > 1) return
        if (item.__typename === 'RuleHead' && item.rules.length === 1) {
          this.$router.push({
            name: 'segment-cards',
            params: { id: item.rules[0].id, discount: item.rules[0].discount[0], type: item.type }
          })
          return
        }

        if (item.__typename === 'AttachedSegment') {
          this.$router.push({
            name: 'segment-cards',
            params: { id: item.id, discount: item.discount[0], type: item.type }
          })
          return
        }
      },

      opemGroup(item, index) {
        this.$emit('open_group-table', item, index)
      },

      getTypeItem(type) {
        if (type === 'SAVING') return 'Накопительная скидка'
        else if (type === 'BIRTHDAY') return 'День рождения'
        else if (type === 'GENDER') return 'Пол'
        else return ''
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          this.items.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []

        this.items.forEach((item) => {
          if (item.__typename === 'RuleHead') {
            if (!this.selected.some((obj) => obj === item.id)) {
              arrayCurrenSelected.push(item.id)
            }
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.select_all = false
        if (currentSeleted.length === this.items.length) this.select_all = true
      },

      clear() {
        this.search = ''
      },

      clearSelected() {
        this.select_all = false
        this.selected = []
      }
    }
  }
</script>

<style scoped lang="scss">
  .icon-triangle-no-active {
    transform: rotate(0deg);
  }

  .icon-triangle-active {
    transform: rotate(90deg);
  }

  .child-block {
    margin-left: 45px;
  }
  .table-name-block {
    display: flex;
  }
  .table-left-text {
    text-align: left;
  }
  .table-navbar {
    position: absolute;
    bottom: 5px;
    right: 0px;
    left: 0px;
  }
  .table-block {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: scroll;
    position: relative;

    :deep th {
      height: 60px;
    }
  }

  .body-id-block {
    position: relative;
  }

  .b-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: -16px;
    top: 50%;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
  }

  .table-status-true {
    width: 91px;
    padding: 0px 12px;
    border-radius: 3px;
    background: #ebf9f5;
    color: #00cb91;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .table-status-false {
    width: 114px;
    border-radius: 3px;
    background: var(--gray-gray-50, #efefef);
    color: var(--text-secondary-gray-400, #888);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
</style>
