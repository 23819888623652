<template>
  <b-sidebar
    id="new-segment-sidebar"
    title="Новый сегмент"
    right
    backdrop
    shadow
    @shown="{}"
    @hidden="hiddenSidebar"
  >
    <template #header="{ hide }">
      Новый сегмент
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <form class="form-new-segment">
        <div class="form-new-segment__input-block">
          <label class="form-new-segment__label">Наименование</label>
          <e-input
            v-model="nameSegment"
            class="form-new-segment__input"
            placeholder="Введите наименование"
            type="text"
          />
        </div>

        <div class="form-new-segment__input-block">
          <label class="form-new-segment__label">Правила</label>

          <div class="segments-head__select-item">
            <e-select
              v-model="currentyRules"
              class="e-select-el"
              :options="allRules"
              :background_color="'#e0edff'"
              :color_text="'#00A3FF'"
              :color_close_svg="'#00A3FF'"
              placeholder="Все правила"
              select_name="roles_select"
              @input="changeRules"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                </div>
              </template>
            </e-select>
          </div>
        </div>

        <template v-if="currentyRules.length > 0">
          <div
            v-for="(option, index) of options"
            :key="option.id"
            class="form-new-segment__options-block"
          >
            <div class="form-new-segment__input-block">
              <label class="form-new-segment__label">Скидка, %</label>
              <e-input
                v-model="option.saleValue"
                class="form-new-segment__input"
                placeholder="Скидка"
                type="number"
              />
            </div>

            <div
              v-if="currentyRules[0]?.name === 'Пол'"
              class="form-new-segment__input-block"
            >
              <label class="form-new-segment__label">Пол</label>
              <div class="form-new-segment__select-item">
                <e-select
                  v-model="option.genderValue"
                  class="e-select-el"
                  :options="genderList"
                  :background_color="'#e0edff'"
                  :color_text="'#00A3FF'"
                  :color_close_svg="'#00A3FF'"
                  placeholder="Пол"
                  select_name="roles_select"
                  @input="changeRules"
                >
                  <template #custom_name="{ item }">
                    <div>
                      <p class="e-select-el__option-name">{{ item.name }}</p>
                    </div>
                  </template>
                </e-select>
              </div>
            </div>

            <div
              v-if="currentyRules[0]?.name === 'Накопительная скидка'"
              class="form-new-segment__input-block"
            >
              <label class="form-new-segment__label">При сумме свыше, ₽</label>
              <div class="form-new-segment__accumulative-discount-input">
                <e-input
                  v-model="option.sumStart"
                  class="form-new-segment__input"
                  placeholder="Введите сумму"
                  type="number"
                />
                <img
                  v-if="index > 0"
                  class="cursor"
                  style="margin-left: 16px"
                  src="/img/integrations/frontol/bin.svg"
                  alt=""
                  @click="deleteOption(option.id)"
                />
              </div>
            </div>

            <div
              v-if="currentyRules[0]?.name === 'День рождения'"
              class="form-new-segment__input-block"
            >
              <label class="form-new-segment__label">Дней до и после</label>
              <div class="form-new-segment__birthday-input-block">
                <e-input
                  v-model="option.beforeBirthday"
                  class="form-new-segment__input-before-birthday"
                  placeholder="До"
                  type="number"
                />

                <e-input
                  v-model="option.afterBirthday"
                  class="form-new-segment__input-after-birthday"
                  placeholder="После"
                  type="number"
                />
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="currentyRules[0]?.name === 'Накопительная скидка'"
          class="form-new-segment__form-block-button"
        >
          <button
            class="form-new-segment__form-button"
            @click.prevent="addOptions"
          >
            <img
              class="cursor"
              style="margin-top: 23px"
              src="/img/integrations/frontol/Plus.svg"
              alt=""
            />
            <p>Добавить</p>
          </button>
        </div>
      </form>
    </template>

    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="createNewSegment(hide)"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  export default {
    name: 'NewSegmentSidebar',

    data() {
      return {
        nameSegment: '',
        currentyRules: [],
        genderList: [
          { id: 1, name: 'М', value: 'male' },
          { id: 2, name: 'Ж', value: 'female' }
        ],
        allRules: [
          { id: 12, name: 'Накопительная скидка', value: 'SAVING' },
          { id: 11, name: 'День рождения', value: 'BIRTHDAY' },
          { id: 10, name: 'Пол', value: 'GENDER' }
        ],
        options: [{ id: 1, saleValue: '', genderValue: [], sumStart: '', beforeBirthday: '', afterBirthday: '' }]
      }
    },

    watch: {
      currentyRules() {
        this.clearFields()
      }
    },

    methods: {
      async createNewSegment(hide) {

        if (this.fieldValidation()) return this.$noty.error('Все поля должны быть заполнены!')

        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/CreateSegment.gql'),
            variables: {
              input: {
                head: {
                  name: this.nameSegment,
                  type: this.currentyRules[0].value
                },
                spec: {
                  days_before: this.options[0].beforeBirthday ? +this.options[0].beforeBirthday : null,
                  days_after: this.options[0].afterBirthday ? +this.options[0].afterBirthday : null,
                  gender: this.options[0].genderValue[0]?.value ? this.options[0].genderValue[0]?.value : null,
                  discount: this.options.map((el) => +el.saleValue),
                  threshold: this.options.map((el) => +el.sumStart)
                }
              }
            }
          })
          this.$noty.show(`Успешно`)
          this.$emit('refetch')
          hide()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      fieldValidation() {
        let validete = false
        if (!this.nameSegment) return true
        if (this.currentyRules.length === 0) return true

        if (this.currentyRules[0].value === 'SAVING') {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].saleValue === '' || this.options[i].sumStart === '') validete = true
          }
        }

        if (this.currentyRules[0].value === 'BIRTHDAY') {
          if (this.options[0].saleValue === '') validete = true
          if (this.options[0].beforeBirthday === '') validete = true
          if (this.options[0].afterBirthday === '') validete = true
        }

        if (this.currentyRules[0].value === 'GENDER') {
          if (this.options[0].saleValue === '') validete = true
          if (this.options[0].genderValue.length === 0) validete = true
        }

        return validete
      },

      changeRules() {
        this.options.splice(1)
      },
      addOptions() {
        const newOption = {
          id: Date.now(),
          saleValue: '',
          genderValue: '',
          sumStart: '',
          beforeBirthday: '',
          afterBirthday: ''
        }
        this.options.push(newOption)
      },

      deleteOption(id) {
        this.options = this.options.filter((el) => el.id !== id)
      },

      clearFields() {
        this.options[0].saleValue = ''
        this.options[0].genderValue = []
        this.options[0].sumStart = ''
        this.options[0].beforeBirthday = ''
        this.options[0].afterBirthday = ''
      },

      hiddenSidebar() {
        this.options.splice(1)
        this.clearFields()
        this.nameSegment = ''
        this.currentyRules = []
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-new-segment {
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;

    &__input-block {
      width: 100%;
    }

    &__birthday-input-block {
      display: flex;
      gap: 2px;
    }

    &__accumulative-discount-input {
      display: flex;
    }

    &__select-item {
      :deep .ms__input {
        height: 30px !important;
      }
      :deep .ms__dropdown-item {
        width: 100px !important;
      }
    }

    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
      margin-top: 22px;
    }

    &__options-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    &__form-block-button {
      margin-top: 12px;
    }

    &__form-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      width: 83px;
      background-color: white;

      p {
        margin: 0;
        color: var(--text-system-blue-400, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      img {
        margin: 0 !important;
      }
    }
  }

  ::v-deep() {
    #new-segment-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }

  .b-sidebar-footer {
    .b-action-buttons {
      padding: 0 22px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
</style>
