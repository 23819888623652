var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-item",
    class: {
      'card-item__background-active': _vm.isActive,
      'card-item__background-no-active': !_vm.isActive
    },
    on: {
      "click": _vm.setTypeCard
    }
  }, [_c('div', {
    staticClass: "card-item__content"
  }, [_c('div', {
    staticClass: "card-item__title-block"
  }, [_c('div', {
    class: {
      'card-item__flag-true': _vm.isActive,
      'card-item__flag-false': !_vm.isActive
    }
  }), _c('div', {
    staticClass: "card-item__title"
  }, [_vm._v(_vm._s(_vm.type_card))])]), _c('div', {
    staticClass: "card-item__description-block"
  }, [_c('div', {
    staticClass: "card-item__description-text"
  }, [_vm._v(_vm._s(_vm.description))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }