<template>
  <div class="segments-head">
    <div class="segments-head__content">
      <div class="segments-head__select-block">
        <div class="segments-head__select-item">
          <e-select
            :value="currentyTpes"
            class="e-select-el"
            :options="allTypes"
            multiple
            :hide_bin="true"
            :max_selected_count="1"
            :background_color="'#e0edff'"
            :color_text="'#00A3FF'"
            :color_close_svg="'#00A3FF'"
            placeholder="Все типы"
            select_name="roles_select"
            @input="
              {
              }
            "
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>

        <div class="segments-head__select-item">
          <e-select
            :value="currentyRules"
            class="e-select-el"
            :options="allRules"
            multiple
            :hide_bin="true"
            :max_selected_count="1"
            :background_color="'#e0edff'"
            :color_text="'#00A3FF'"
            :color_close_svg="'#00A3FF'"
            placeholder="Все правила"
            select_name="roles_select"
            @input="
              {
              }
            "
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>
      </div>

      <div class="segments-head__button-block">
        <div>
          <div class="b-table__actions ml-auto">
            <b-button
              v-b-toggle.new-segment-sidebar
              class="cursor"
              variant="primary"
              @click="
                {
                }
              "
            >
              <img
                src="/img/icons/btn-plus.svg"
                class="mr"
                alt=""
              />
              Добавить сегмент
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SegmentsHeadFilter',

    components: {},

    data() {
      return {
        currentyTpes: [],
        currentyRules: [],
        file: null,
        allTypes: [
          { id: 12, name: 'Скидочные' },
          { id: 11, name: 'Бонусные' }
        ],
        allRules: [
          { id: 12, name: 'Накопительная скидка' },
          { id: 11, name: 'День рождения' },
          { id: 10, name: 'Пол' }
        ]
      }
    },

    methods: {}
  }
</script>

<style scoped lang="scss">
  :deep .search {
    width: 1px;
  }
  .segments-head {
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__select-block {
      display: flex;
      align-items: center;
    }

    &__select-item {
      width: 280px;
      margin-right: 25px;
    }

    &__button-block {
      display: flex;
      align-items: center;
    }
  }
</style>
