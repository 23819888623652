import { render, staticRenderFns } from "./SegmentsTable.vue?vue&type=template&id=3d323a94&scoped=true&"
import script from "./SegmentsTable.vue?vue&type=script&lang=js&"
export * from "./SegmentsTable.vue?vue&type=script&lang=js&"
import style0 from "./SegmentsTable.vue?vue&type=style&index=0&id=3d323a94&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d323a94",
  null
  
)

export default component.exports